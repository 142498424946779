<template>
  <div class="no-data">
       <img  :src="require('@/assets/images/no-data.png')" alt="No data">
       <p>
           Sorry currently there is no data!
       </p>
  </div>
</template>

<script>
export default {

}
</script>

<style>
.no-data{
        text-align: center;
}
.no-data img{
    max-width:480px


}

</style>