<template>
  <Layout>
    <div class="row mb-2">
      <div class="col-lg-7 col-md-7 col-sm-12">
        <filter-job v-on:changeStatus="changeStatus" />
      </div>
      <div class="col-lg-5 col-md-5 col-sm-12"><search-input /></div>
    </div>

    <b-skeleton-wrapper :loading="loading">
      <template #loading>
        <Skeleton />
      </template>

      <div v-if="jobs.length" class="row">
        <div class="col-xl-4 col-md-6 p-0" v-for="job in jobs" :key="job._id">
          <ScheduledJob :job="job" />
        </div>
      </div>

      <div v-else>
        <no-data />
      </div>
    </b-skeleton-wrapper>
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main";
import ScheduledJob from "@/components/partner/scheduled-job/job.vue";
import SearchInput from "@/components/partner/scheduled-job/search-input.vue";
import FilterJob from "@/components/partner/scheduled-job/filter-job.vue";
import { job } from "@/config/api/partner/job";
import NoData from "@/components/no-data.vue";
import Skeleton from "@/components/partner/scheduled-job/skelton.vue";
export default {
  components: {
    Layout,
    ScheduledJob,
    SearchInput,
    FilterJob,
    NoData,
    Skeleton,
  },
  data() {
    return {
      jobs: [],

      loading: false,
    };
  },
  watch:{
    status(){
       this.loadJobs();
    }
  },
  computed: {
    status() {
      return this.$route.params.id || 1;
    },
  },
  created() {
    this.loadJobs();
  },
  methods: {
    loadJobs() {
      this.loading = true;
      const api = job.get;
      api.params = {
        status: this.$route.params.id||1,
      };
      this.generateAPI(api)
        .then((res) => {
          this.loading = false;
          this.jobs = res.data.jobs;
        })
        .catch((err) => {
          console.log(err.data);
        });
    },
    changeStatus(status) {
      if(this.$route.path !== "/partner/jobs/" + status)
      this.$router.push({ name: "ScheduledJob", params: { id: status } });
     
    },
  },
};
</script>

<style></style>
