<template>
  <div class="row">
    <div class="col-xl-4 col-md-6 p-0" v-for="index in 6" :key="index">
      <div class="scheduled-job-card">
        <div class="row mb-2">
          <div class="col-8">
            <strong> <b-skeleton width="70%"></b-skeleton></strong>

            <div style="color: #8e94b5">
              <b-skeleton width="50%"></b-skeleton>
            </div>
          </div>
          <div class="col-4">
            <div>
              <b-skeleton width="100%"></b-skeleton>

              <b-skeleton width="100%"></b-skeleton>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-end">
          <b-skeleton width="10%"></b-skeleton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.scheduled-job-card {
  background-color: #fff;
  margin: 12px;
  min-height: 100px;
  border-radius: 8px;
  padding: 18px 12px;
  box-shadow: 0px 0px 12px 1px rgb(215 215 215 / 75%);
  -webkit-box-shadow: 0px 0px 12px 1px rgb(215 215 215 / 75%);
  -moz-box-shadow: 0px 0px 12px 1px rgb(215 215 215 / 75%);
}
</style>
