<template>
  <div class="scheduled-job-card">
    <div class="row mb-2">
      <div class="col-8">
        <strong> {{ job.vehicle.owner_id.email }}</strong>

        <div style="color: #8e94b5">
          +{{ job.vehicle.owner_id.country_code }}-{{
            job.vehicle.owner_id.phone_number
          }}
        </div>
      </div>
      <div class="col-4">
        <div class="text-center" style="border-left: 1px solid #e6e6e6">
          <div v-if="job.wrapping_schedule_date">
            {{ moment(job.wrapping_schedule_date).format("hh:mm A") }}

            <div style="color: #8e94b5">
              {{ moment(job.wrapping_schedule_date).format("LL") }}
            </div>
            <p class="m-0" style="font-size:12px">
              Scheduled At
            </p>
          </div>
        </div>
      </div>
    </div>

    <router-link
      :to="{ name: 'JobDetailsForPartner', params: { id: job._id } }"
    >
      <div style="text-align: end; font-weight: 600">
        View Job Details
        <img
          style="
            height: 14px;
            display: inline-block;
            margin-top: -1px;
            margin-left: 8px;
          "
          :src="require('@/assets/images/partner/Icon feather-arrow-right.png')"
          alt=""
          srcset=""
        />
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  props: ["job"],
};
</script>

<style scoped>
.scheduled-job-card {
  background-color: #fff;
  margin: 12px;
  border-radius: 8px;
  padding: 18px 12px;
  box-shadow: 0px 0px 12px 1px rgb(215 215 215 / 75%);
  -webkit-box-shadow: 0px 0px 12px 1px rgb(215 215 215 / 75%);
  -moz-box-shadow: 0px 0px 12px 1px rgb(215 215 215 / 75%);
}
</style>
