<template>
  <div class="partner-search-bar">
    <FormulateInput
      type="search"
      name="sample"
      label="Search by Driver ID Or Mobile Number"
    />
  </div>
</template>

<script>
export default {};
</script>

<style>
.partner-search-bar {
  margin-top: 6px;
}
.partner-search-bar input {
  border-radius: 8px !important;
  overflow: hidden !important;
  background-color: #fff !important;
}
</style>
